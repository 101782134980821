/* =========================================================
	ANGIEHERRERA.COM STYLES
	Loosely based on CUBE CSS - https://cube.fyi

	- 01. Modern CSS Reset
	- 02. Global custom properties
	- 03. Default / classless styles
	- 04. Utilities
	- 05. Page layout
========================================================= */
@charset 'utf-8';

/* -----------------------------------------------
   01. Modern CSS Reset
   - https://andy-bell.co.uk/a-more-modern-css-reset/
   - https://www.joshwcomeau.com/css/custom-css-reset/
----------------------------------------------- */
/* Use border-box by default, globally */
*, *::before, *::after { box-sizing: border-box; }

/* Prevent font size inflation */
html {
	-moz-text-size-adjust: none;
	-webkit-text-size-adjust: none;
	text-size-adjust: none;
 }

/* Remove default margin in favor of better */
/* control in authored CSS */
body, h1, h2, h3, h4, h5, h6, p,
figure, blockquote, dl, dd { margin: 0; }

/* Remove list styles on ul, ol elements with a list role, */
/* which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
	list-style: none;
	margin: 0;
	padding: 0;
}

/* Set core body defaults */
body {
   line-height: 1.5;
   min-height: 100vh;
}

/* Set shorter line heights on headings and interactive elements */
h1, h2, h3, h4, h5, h6,
button, input, label { line-height: 1.1; }

/* Balance text wrapping on headings */
h1, h2, h3,
h4, h5, h6 { text-wrap: balance; }

/* Prevent orphans - may need to set max-width */
p { text-wrap: pretty; }

/* A elements that don't have a class get default styles */
a:not([class]) { text-decoration-skip-ink: auto; }

/* Inherit fonts for inputs and buttons */
input, button,
textarea, select { font: inherit; }

/* Make sure textareas without a rows attribute are not tiny */
textarea:not([rows]) { min-height: 10rem; }

/* Improve media defaults */
img, picture, video, canvas, svg {
   display: block;
   height: auto;
   max-width: 100%;
}

/* There is no good reason elements default to 300px, */
/* and audio files are unlikely to come with a width attribute */
audio { width: 100%; }

/* Add cursors by default */
button,
[type="button"],
[type="reset"],
[type="submit"],
[type="image"],
[type="checkbox"],
[type="radio"],
summary { cursor: pointer; }

/* Avoid text overflows */
p, h1, h2, h3, h4, h5, h6 { overflow-wrap: break-word; }

/* Overflow by default is bad */
pre { white-space: pre-wrap; }

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
   *,
   *::before,
   *::after {
     animation-duration: 0.01ms !important;
     animation-iteration-count: 1 !important;
     transition-duration: 0.01ms !important;
     scroll-behavior: auto !important;
   }
}

/* And for those that don't have a preference */
@media (prefers-reduced-motion: no-preference) {
	:has(:target) {
		scroll-behavior: smooth;
		scroll-padding-top: 1.25rem;
	}
}

/* -----------------------------------------------
	02. Global custom properties
----------------------------------------------- */
:root {
	/* Fonts */
   --font-body: Helvetica, sans-serif;
   --font-display: Helvetica, sans-serif;

	/* Fluid font sizes */
   /* https://utopia.fyi/type/calculator/?c=320,16,1.2,1024,16,1.25,6,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
   --fs--2: clamp(0.64rem, 0.7192rem + -0.1237vi, 0.6944rem);
	--fs--1: clamp(0.8rem, 0.8485rem + -0.0758vi, 0.8333rem);
	--fs-0: clamp(1rem, 1rem + 0vi, 1rem);
	--fs-1: clamp(1.2rem, 1.1773rem + 0.1136vi, 1.25rem);
	--fs-2: clamp(1.44rem, 1.3843rem + 0.2784vi, 1.5625rem);
	--fs-3: clamp(1.728rem, 1.6257rem + 0.5116vi, 1.9531rem);
	--fs-4: clamp(2.0736rem, 1.9064rem + 0.8359vi, 2.4414rem);
	--fs-5: clamp(2.4883rem, 2.2322rem + 1.2805vi, 3.0518rem);
	--fs-6: clamp(2.986rem, 2.6093rem + 1.8834vi, 3.8147rem);

	/* Colors */
	--blue-500: #18A7E0;
	--blue-600: #0085BC;
	--blue-700: #006599;
	--blue-800: #004678;
	--blue-900: #002A57;
	--blue-950: #001A43;

	--link-color: rgba(255, 255, 255, 0.6);
	--link-color-hover: rgba(255, 255, 255, 0.9);

	/* z-index */
   --z-below: -1; /* and this for all values below the base */
   --z-base: 0;
   --z-above: 1; /* use this for all values above the base */
   --z-navigation: calc(var(--z-above) + 10);
   --z-modal: calc(var(--z-navigation) + 10);
}

/* -----------------------------------------------
	03. Default / classless styles
----------------------------------------------- */
body {
	color: var(--link-color);
   display: grid;
	align-items: center;
	background: linear-gradient(180deg, #001a43 30%, #012f5d 100%);
	justify-content: center;
   font-family: var(--font-body);
   font-size: 100%;
   height: 100%;
   line-height: 1.5;
}

a {
	color: var(--link-color);
	transition: all 0.3s ease-in-out;
}
a:hover { color: var(--link-color-hover); }

/* -----------------------------------------------
	04. Utilities
----------------------------------------------- */
/* Flow (https://andy-bell.co.uk/my-favourite-3-lines-of-css/) */
.flow > * + * {
   margin-block-start: var(--flow-space, 1rem);
}

/* -----------------------------------------------
	05. Page layout
----------------------------------------------- */
.container {
	max-width: 700px;
	margin-inline: 2rem;
}

.card {
	border-radius: 10px;
	background-color: var(--blue-900);
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	padding: 3rem;
	position: relative;
	box-shadow:
		0px 1.9px 2.2px rgba(0, 0, 0, 0.011),
		0px 4.5px 5.3px rgba(0, 0, 0, 0.016),
		0px 8.4px 10px rgba(0, 0, 0, 0.02),
		0px 15px 17.9px rgba(0, 0, 0, 0.024),
		0px 28px 33.4px rgba(0, 0, 0, 0.029),
		0px 67px 80px rgba(0, 0, 0, 0.04)
	;
}

.card .headshot {
	max-width: 200px;
	border-radius: 500px;
	position: relative;
	margin-block-start: -100px;
	border: 4px solid var(--link-color);
}

.card .heading {
	--flow-space: 1.5rem;
	color: white;
}

.container .links {
	display: flex;
	justify-content: center;
	gap: 2rem;
	margin-block-start: 1.25rem !important;
	font-size: var(--fs--2);
}

.container svg {
	width: 47px;
	margin-inline: auto;
	margin-block-start: 2rem;
	fill: rgba(255, 255, 255, 0.15);
}
